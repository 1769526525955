<template>
  <div>
    <Table
      label="Post"
      :actions="actions"
      :items="$store.getters['post/tableData']"
      :fields="fields"
      :loading="$store.state.post.loadingTable"
      :filter="$store.state.post.tableFilter"
      :sort="$store.state.post.tableSort"
      :sort-options="sortOptions"
      :keyword="$store.state.post.tableKeyword"
      :pagination="$store.state.post.tablePagination"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @paginate="paginate"
      @search="search"
    />
    <!-- Delete Post modal -->
    <b-modal
      v-model="deleteModalVisible"
      title="Delete Post"
      ok-title="Yes, delete"
      cancel-variant="white"
      @ok="deletePost"
    >
      Are you sure want to delete this post?. This action cannot be undone.
    </b-modal>
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [],
      deleteModalVisible: false,

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'createdAt',
          label: 'Published at',
          type: 'datetime',
        },
        // {
        //   key: 'postMedia_image',
        //   label: 'Image',
        //   type: 'image',
        // },
        {
          key: 'postMedia_videos',
          label: 'Video',
          type: 'videos',
        },
        {
          key: 'postMedia_images',
          label: 'Image',
          type: 'images',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'content',
          label: 'Content',
          visibility: ['view'],
        },
        {
          key: 'authorUser_nickname',
          label: 'User',
          type: 'profile',
          image: 'authorUser_avatar',
          sortable: false,
        },
        {
          key: 'postSummary_value',
          label: 'Summary',
          type: 'component',
          map: {
            name: 'PostSummaryField',
          },
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],

      actions: ['view', {
        type: 'other',
        children: [
          {
            label: 'Delete',
            event: this.confirmDelete,
            visibility: {
              callback: data => !data.deletedAt && this.canAccess('manage', 'Post'),
            },
          },
        ],
      }],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('post/getTableData')
    },
    filterData(val) {
      this.$store.commit('post/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('post/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('post/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('post/SET_TABLE_KEYWORD', val)
    },
    deletePost() {
      this.$store.dispatch('post/delete', this.selectedData.id)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'The post has been deleted!',
              variant: 'success',
            },
          })

          this.deleteModalVisible = false
          this.loadData()
        })
    },
    confirmDelete(data) {
      this.selectedData = data.item
      this.deleteModalVisible = true
    },
  },
}
</script>
